<template>
  <McTable
    :isHideMenu="false"
    :isHideAdd="false"
    :isHideSettings="false"
    :isLoading="isFetching"
    :search="search"
    :tabs="tabs"
    :headers="headers"
    :items="items"
    :footerProps="footerProps"
    :paging="paging"
    :pagingOptions="pagingOptions"
    :statusBorder="true"
  />
</template>

<script>
// @ is an alias to /src

// Utilities
import {
  // cloneDeep,
  // get
} from 'lodash'

import {
  mapState,
  // mapActions,
  // mapMutations
} from 'vuex'
import { initComponent } from '@/lib'

import {
  UPDATE_USER_STATE,
  REQUEST_USER_LIST
} from '@/store/modules/user'

export default initComponent ('Users', {
  isPage: true,
  components : {
    // Chart1: () => import('./components/widgets/ChartOne'),
    McTable: () => import('@/components/base//Table'),
  },
    computed: {
    ...mapState({
      isFetching: (state) => state.user.isFetching,
      isProcessing: (state) => state.user.isProcessing,
      items: (state) => state.user.items,
      paging: (state) => state.user.paging,
      item: (state) => state.user.item,
      search: (state) => state.user.search,
      tabs: (state) => state.user.tabs,
    }),
    pagingOptions() {
      let option = {
        state: 'aisleGuide',
        commit: UPDATE_USER_STATE,
        dispatch: REQUEST_USER_LIST,
      }
      return option;
    }
  },
  data: () => ({
    headers: [
      {
        text: '',
        align: 'center',
        sortable: false,
        value: 'status',
        width: 70,
      },
      {
        text: 'Name',
        align: 'start',
        class: 'tw-px-12',
        sortable: false,
        width: '20%',
        value: 'name',
      },
      { text: 'Last Visit', value: 'calories' },
      { text: 'Information 1', value: 'fat' },
      { text: 'Information 2', value: 'carbs' },
      { text: 'Information 3', value: 'protein' },
      { text: 'Information 4', value: 'iron' },
    ],
  }),
  methods: {
    async onReady () {
    },
  }
})
</script>

<style lang="sass" scoped>

</style>